<template lang="pug">
article.thanks
  .thanks__cont.cont
    div(v-if="mod === 'thanks'")
      h1.thanks__caption.caption Спасибо за заявку!
      p.thanks__text.text-big Наш менеджер перезвонит {{worktime.mask}}
      router-link.thanks__link.text.button-orange(:to="{ name: 'Home'} ") На главную
    div(v-else)
      h1.thanks__caption.caption Ошибка 404
      p.thanks__text.text-big Такой страницы пока нет
      router-link.thanks__link.text.button-orange(:to="{ name: 'Home'} ") На главную
</template>

<script>
import '@/assets/styles/components/thanks.sass'
import { worktime } from '@/assets/scripts/variables'

export default {
  name: 'Thanks404',
  props: ['mod'],
  data () {
    return {
      worktime
    }
  }
}
</script>
